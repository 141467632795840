import { default as NextHead } from "next/head"
import React, { useEffect } from "react"
import Script from "next/script"
import { initGoogleTagManager } from "@/lib/google-tag-manager"
import { useAppContext } from "@/lib/contexts/appContext"
import { fetchPathData } from "@/lib/api"
import { Layout } from "@/components/base/layout"

const Index = ({ pathData }) => {
  const { meta, resetAppContext } = useAppContext()

  useEffect(() => {
    const { gtmContainerId } = meta
    if (gtmContainerId) {
      initGoogleTagManager(gtmContainerId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    resetAppContext()

    const nextRoute = {
      event: "nextRoute",
      path: pathData.path
    }

    window.dataLayer = window.dataLayer || []
    window.dataLayer.push(nextRoute)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathData])

  return (
    <>
      <Head data={pathData?.head} />
      {meta.optimizeId && (
        <Script
          strategy="afterInteractive"
          type="text/javascript"
          data-blockingmode="tracking"
          src={`https://www.googleoptimize.com/optimize.js?id=${meta.optimizeId}`}
        />
      )}
      <Layout />
    </>
  )
}

function Head({ data }) {
  return (
    <NextHead>
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      {data &&
        data.map((meta, index) => {
          const Tag = meta.tag
          const attrs = meta.attrs

          if (meta.tag === "title") {
            return <Tag key={index}>{attrs.innerText}</Tag>
          }

          if (meta.tag === "script") {
            return <Tag key={index} type="text/javascript" src={attrs.src} />
          }

          if (meta.tag === "meta" && attrs.name === "robots") {
            return (
              <Tag
                key={index}
                name={attrs.name}
                content={Object.values(attrs.content).join(", ")}
              />
            )
          }

          if (
            typeof attrs.content === "object" &&
            attrs?.property === "og:images"
          ) {
            const { ...content } = attrs
            const [url, props] = Object.entries(content?.content)[0]
            return (
              <React.Fragment key={index}>
                <meta property="og:image" content={url} />
                <meta property="og:image:width" content={props.width} />
                <meta property="og:image:height" content={props.height} />
                <meta property="og:image:secure_url" content={props.url} />
              </React.Fragment>
            )
          }

          return <Tag key={index} {...attrs} />
        })}
    </NextHead>
  )
}

export async function getStaticPaths() {
  return {
    paths: [],
    fallback: "blocking"
  }
}

export async function getStaticProps(context) {
  let slugs = context.params?.slug

  if (slugs && slugs[0] === "index") {
    slugs = []
  }

  const path = slugs?.length ? "/" + slugs.join("/") + "/" : "/"

  if (context.preview) {
    return {
      redirect: {
        destination: "/api/stopPreviewSession" + path
      },
      revalidate: 1
    }
  }

  const pathData = await fetchPathData({
    path,
    isPreview: context.preview && path === context?.previewData?.path
  })

  if (!pathData) {
    return {
      notFound: true,
      revalidate: 1
    }
  }

  if (pathData.redirect) {
    return {
      redirect: {
        destination: pathData.path,
        permanent: [301, 308].includes(pathData.status)
      },
      revalidate: 1
    }
  }

  return {
    props: { pathData },
    revalidate: 1
  }
}

export default Index
