import { usePathData } from "@/lib/contexts/appContext"
import { templateMapping } from "@/lib/templateMapping"
import NotFound from "@/components/base/notFound"

import Header from "./header/header"
import Footer from "./footer/footer"

export function Layout() {
  const pathData = usePathData()

  const Template = templateMapping[pathData.template] ?? NotFound

  return (
    <>
      <Header />
      <Template />
      <Footer />
    </>
  )
}
